
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import variables from "@/router/api";
import Gender from "@/components/reusable/Gender.vue";

export default defineComponent({
  name: "create-cooperative-step-2",
  components: {
    Field,
    ErrorMessage,
    Gender
  },
  data() {
    return {
      idClass: "col-xl-6",
      addressClass: "col-xl-12",
      displayDates: false,
      startDateMax: variables.currentDate(),
      endDateMin: variables.currentDate(),
      endDateMax: "" as any,
      identificationIssueDate: "",
      identificationImage: "",
      avatar: ""
    };
  },
  created() {
    this.endDateMax = variables.addYearToDate(variables.currentDate(), 1);
  },
  methods: {
    acceptNumberOnly(amount) {
      return variables.acceptNumberOnly(amount);
    },
    identificationIssueDateAndExpiry(event) {
      const value = event.target.value;

      if (value == "Drivers License" || value == "International Passport") {
        this.displayDates = true;
        this.idClass = "col-xl-4";
        this.addressClass = "col-xl-8";
      } else {
        this.displayDates = false;
        this.idClass = "col-xl-6";
        this.addressClass = "col-xl-12";
      }
    },
    convertImagetoBase64(event, fieldName) {
      const selectedImage = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);

      reader.onload = (e) => {
        const value = reader.result as string;
        fieldName == "idImage"
          ? (this.identificationImage = value)
          : (this.avatar = value);
      };

      reader.onerror = function (error) {
        variables.toastAlert(error, "error");
      };
    },
    getStartDate(event) {
      const today = new Date(this.identificationIssueDate);
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const year = today.getFullYear() + 1;

      this.startDateMax = variables.addYearToDate(event.target.value, 2);
      this.endDateMax = variables.addYearToDate(event.target.value, 2);
      this.endDateMin = year + "-" + mm + "-" + dd;

      const dateIssued = document.getElementById(
        "date-of-issue"
      ) as HTMLInputElement;
      const dateExpired = document.getElementById(
        "date-of-expiry"
      ) as HTMLInputElement;

      dateIssued.value != "" ? dateIssued.value : "";
      dateExpired.value != "" ? dateExpired.value : "";
    }
  }
});
