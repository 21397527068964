
import { defineComponent, onMounted, computed, ref } from "vue";
import { StepperComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useForm } from "vee-validate";
import Step1 from "@/views/admin/users/cooperatives-societies/steps/create/Step1.vue";
import Step2 from "@/views/admin/users/cooperatives-societies/steps/create/Step2.vue";
import Step3 from "@/views/admin/users/cooperatives-societies/steps/create/Step3.vue";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import store from "@/store";
import GoBackButton from "@/components/reusable/GoBackButton.vue";

// eslint-disable-next-line no-redeclare
interface Step1 {
  cooperativeName: string;
  memberStrength: string;
  cooperativeLogo: string;
  cooperativeEmail: string;
  contactNumber: string;
  rcNumber: string;
  taxNumber: string;
  bank: string;
  accountNumber: string;
  accountName: string;
  bvn: string;
  cooperativeAddress: string;
}

// eslint-disable-next-line no-redeclare
interface Step2 {
  firstName: string;
  middleName: string;
  lastName: string;
  emailAddress: string;
  gender: string;
  phoneNumber: string;
  dateOfBirth: string;
  meansOfIdentification: string;
  identificationImage: string;
  identificationNumber: string;
  identificationIssueDate: string;
  identificationExpiryDate: string;
  avatar: string;
  address: string;
}

// eslint-disable-next-line no-redeclare
interface Step3 {
  rates: {
    publicId: string;
    value: string;
  };
}

interface CreateCooperative extends Step1, Step2, Step3 {}

export default defineComponent({
  name: "create-cooperative-society",
  components: {
    Step1,
    Step2,
    Step3,
    GoBackButton,
  },
  data() {
    return {
      displayDates: false,
      banks: [],
      rates: [],
      rateRoute: "",
      createRoute: "",
      returnRoute: "",
    };
  },
  setup() {
    const _stepperObj = ref<StepperComponent | null>(null);
    const horizontalWizardRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);

    const formData = ref<CreateCooperative>({
      cooperativeName: "",
      memberStrength: "",
      cooperativeLogo: "",
      cooperativeEmail: "",
      contactNumber: "",
      rcNumber: "",
      taxNumber: "",
      bank: "",
      accountNumber: "",
      accountName: "",
      bvn: "",
      cooperativeAddress: "",
      firstName: "",
      middleName: "",
      lastName: "",
      emailAddress: "",
      gender: "",
      phoneNumber: "",
      dateOfBirth: "",
      meansOfIdentification: "",
      identificationImage: "",
      identificationNumber: "",
      identificationIssueDate: "",
      identificationExpiryDate: "",
      avatar: "",
      address: "",
      rates: {
        publicId: "",
        value: "",
      },
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        horizontalWizardRef.value as HTMLElement
      );
    });

    const CreateOwnedBuildingSchema = [
      Yup.object({
        cooperativeName: Yup.string()
          .required()
          .label("Cooperative name"),
        memberStrength: Yup.number()
          .required()
          .label("Member strength"),
        cooperativeLogo: Yup.mixed().label("Cooperative logo"),
        cooperativeEmail: Yup.string()
          .required()
          .label("Cooperative email"),
        cooperativeAddress: Yup.string()
          .required()
          .label("Cooperative address"),
        contactNumber: Yup.number()
          .required()
          .label("Contact number"),
        rcNumber: Yup.number()
          .required()
          .label("RC number"),
        taxNumber: Yup.number()
          .required()
          .label("Tax number"),
        bank: Yup.mixed()
          .required()
          .label("Bank"),
        accountNumber: Yup.number()
          .required()
          .label("Account number"),
        accountName: Yup.string()
          .required()
          .label("Account name"),
      }),
      Yup.object({
        firstName: Yup.string()
          .required()
          .label("First name"),
        middleName: Yup.string()
          .notRequired()
          .label("Middle name"),
        lastName: Yup.string()
          .required()
          .label("Last name"),
        emailAddress: Yup.string()
          .required()
          .label("Email address"),
        gender: Yup.string()
          .required()
          .label("Gender"),
        phoneNumber: Yup.number()
          .required()
          .label("Phone number"),
        dateOfBirth: Yup.string()
          .required()
          .label("Date of birth"),
        meansOfIdentification: Yup.mixed()
          .notRequired()
          .label("Means of identification"),
        identificationNumber: Yup.string()
          .required()
          .label("Means of identification number"),
        identificationImage: Yup.mixed()
          .required()
          .label("Identification image"),
        identificationIssueDate: Yup.date()
          .notRequired()
          .label("Date of issue"),
        identificationExpiryDate: Yup.date()
          .notRequired()
          .label("Date of expiry"),
        address: Yup.string()
          .required()
          .label("Home address"),
        avatar: Yup.string()
          .required()
          .label("Avatar"),
      }),
      // Yup.object({
      //   value: Yup.string()
      //     .notRequired()
      //     .label("Rate value"),
      //   publicId: Yup.mixed()
      //     .notRequired()
      //     .label("Rate public ID"),
      // }),
    ];

    // const currentSchema = computed(() => {
    //   return CreateOwnedBuildingSchema[currentStepIndex.value];
    // });

    const { resetForm, handleSubmit } = useForm<Step1 | Step2 | Step3>({
      // validationSchema: currentSchema,
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    resetForm({
      values: {
        ...formData.value,
      },
    });

    const handleStep = handleSubmit((values) => {
      formData.value = {
        ...formData.value,
        ...values,
      };

      // console.log(values);

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();

      onMounted(() => {
        setCurrentPageBreadcrumbs("Create", ["Users", "Cooperative Society"]);
      });
    };

    const submitButton1 = ref<HTMLElement | null>(null);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Cooperative Society", ["Create"]);
    });

    return {
      submitButton1,
      horizontalWizardRef,
      previousStep,
      handleStep,
      totalSteps,
      currentStepIndex,
    };
  },
  created() {
    // Redirect to page after successfully login
    const user = store.getters.currentUser;

    // The the default rate route
    this.rateRoute =
      user.role == variables.SUPER_ADMIN_USER_ROLE
        ? variables.SUPERADMIN_COOPERATIVE_RATE_ROUTE
        : variables.BROKER_COOPERATIVE_RATE_ROUTE;

    // The the default rate route
    this.createRoute =
      user.role == variables.SUPER_ADMIN_USER_ROLE
        ? variables.SUPERADMIN_CREATE_COOPERATIVE_ROUTE
        : variables.BROKER_CREATE_COOPERATIVE_ROUTE;

    this.returnRoute =
      user.role == variables.SUPER_ADMIN_USER_ROLE
        ? "admin-cooperative-societies"
        : "broker-cooperative-societies";

    // Get the banks and rates list
    this.getRates();

    //Set page title
    document.title =
      "Create Cooperative Society | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getRates() {
      // Get the private motor rate
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${this.rateRoute}`)
          .then(({ data }) => {
            //Assign data to form fields
            this.rates = data.data.rates;
            this.banks = data.data.banks;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    formSubmit(e) {
      e.preventDefault();
      const submitButton1 = document.getElementById(
        "create_cooperative_submit_btn"
      );

      if (submitButton1) {
        // Activate indicator
        submitButton1.setAttribute("data-kt-indicator", "on");

        const form = document.forms[0];
        const formData = new FormData(form);
        // return new Response(formData).text().then(console.log);

        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.post(this.createRoute, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
            .then((response) => {
              submitButton1.removeAttribute("data-kt-indicator");
              variables.toastAlert(response.data.data.message, "success");
              this.$router.push({ name: this.returnRoute });
            })
            .catch(function(error) {
              submitButton1.removeAttribute("data-kt-indicator");
              console.info(error);
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      }
    },
  },
});
